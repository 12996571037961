html, body, #root {
  background-color: #f3f7f7;
  font-size: 16px;
}

@media (max-width: 767.98px) { 
.login-panel {
 width: 100%; 
}
}


@media print {
  html, body, #root {
  background-color: #fff;
}
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-header {
	margin-bottom: 15px;
}


.app-table{
  width: 100%;
}

.app-table thead th {
  background-color: #ddd;
}

.app-table th,
.app-table td {
  border: 1px solid #333;
  padding: 5px;
}

.app-table .money {
  text-align: right;
}

table.center th {
  text-align: center;
}

.app-table .center {
  text-align: center;
}

.app-table input, .app-table textarea {
	width: 100%;
}

.pagination .page-link:hover {
  cursor: pointer;
} 

.pagination .page-link:focus {
  box-shadow: initial;
}

.react-datepicker-popper {
  z-index: 100;
}